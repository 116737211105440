import React from "react";
import Icon from "../Icon";

const ArrowRightAltIcon = () => {
  return (
    <Icon viewBox="0 0 18 19">
      <path d="M12.0075 9.24402H3V10.744H12.0075V12.994L15 9.99402L12.0075 6.99402V9.24402Z" />
    </Icon>
  );
};

export default ArrowRightAltIcon;
