import React from "react";
import Icon from "../Icon";

const ArrowLeftIcon = () => {
  return (
    <Icon viewBox="0 0 18 19">
      <path d="M5.9925 10.744H15V9.24402H5.9925V6.99402L3 9.99402L5.9925 12.994V10.744Z" />
    </Icon>
  );
};

export default ArrowLeftIcon;
