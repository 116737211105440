import React from "react"
import Slider from "react-slick"

import "../stylesheets/slick.css"
import "../stylesheets/slick-theme.css"

const Carousel = ({ settings, children, className }) => {
  return (
    <Slider className={className} {...settings}>
      {children}
    </Slider>
  )
}

export default Carousel
