import React from "react";
import Icon from "../Icon";

const ArrowLeftAltIcon = () => {
  return (
    <Icon viewBox="0 0 16 16">
      <path d="M13.9996 7.33339H4.27563L7.8043 3.80473L6.86163 2.86206L1.72363 8.00006L6.86163 13.1381L7.8043 12.1954L4.27563 8.66673H13.9996V7.33339Z" />
    </Icon>
  );
};

export default ArrowLeftAltIcon;
