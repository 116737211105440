import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Seo from "../components/Seo";
import Row from "../components/Row";
import Col from "../components/Col";
import styled from "styled-components";
import Button from "../components/Button";
import ArrowLeftIcon from "../components/Icons/ArrowLeftIcon";
import ArrowLeftAltIcon from "../components/Icons/ArrowLeftAltIcon";
import ArrowRightAltIcon from "../components/Icons/ArrowRightAltIcon";
import Carousel from "../components/Carousel";
import { BREAKPOINTS } from "../utils/styled_config";

export const query = graphql`
  query ($slug: String!) {
    projectsJson(slug: { eq: $slug }) {
      name
      categories
      description
      client
      launched_date
      website
      project_images {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP]
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
`;

const Works = ({ data, pageContext }) => {
  const work = data.projectsJson;

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <Layout>
      <Seo title={work.name} />
      <ProjectSlider>
        <NavMobile>
          <Nav to="/works">
            <ArrowLeftAltIcon />
            <PreviousSpan>Back to projects</PreviousSpan>
          </Nav>
        </NavMobile>
        <Slider>
          <Carousel settings={settings}>
            {work.project_images.map((img, idx) => (
              <div key={idx}>
                <GatsbyImage image={getImage(img)} alt="slider" />
              </div>
            ))}
          </Carousel>
        </Slider>
      </ProjectSlider>
      <ProjectDetails>
        <FillMain>
          <NavDesktop>
            <Nav to="/works">
              <ArrowLeftAltIcon />
              <PreviousSpan>Back to projects</PreviousSpan>
            </Nav>
          </NavDesktop>
          <ProjectTitle>{work.name}</ProjectTitle>
          <Info>{work.categories}</Info>
          {work.description && (
            <DynamicContent
              dangerouslySetInnerHTML={{ __html: work.description }}
            ></DynamicContent>
          )}
          <Info>
            Client: {work.client || "N/A"}
            <br />
            Launched: {work.launched_date || "N/A"}
            {work.website && (
              <>
                <br />
                Website:{" "}
                <WebsiteLink
                  href={work.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {work.website.replace("https://www.", "")}
                </WebsiteLink>
              </>
            )}
          </Info>
          <Button to="/contact-us" block="true">
            Get in Touch
          </Button>
          <Spacer />
        </FillMain>
        <Full>
          <Row justify="space-between">
            <Col col="auto">
              {pageContext.previous && (
                <Nav to={`/works/${pageContext.previous.slug}`}>
                  <ArrowLeftIcon />
                  <PreviousSpan>Previous Project</PreviousSpan>
                </Nav>
              )}
            </Col>
            <Col col="auto">
              {pageContext.next && (
                <Nav to={`/works/${pageContext.next.slug}`}>
                  <NextSpan>Next Project</NextSpan>
                  <ArrowRightAltIcon />
                </Nav>
              )}
            </Col>
          </Row>
        </Full>
      </ProjectDetails>
    </Layout>
  );
};

const Layout = styled.div``;

const ProjectDetails = styled.div`
  padding: 15px;

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    width: 500px;
    padding: 30px 60px 15px 30px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-height: 100vh;
  }
`;

const Slider = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    width: 640px;
    min-width: 50%;
  }
`;

const WebsiteLink = styled.a`
  color: #fff;

  &:hover,
  &:focus {
    color: #fff;
    text-decoration: underline;
  }
`;

const ProjectSlider = styled.div`
  padding: 15px;

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    width: calc(100% - 500px);
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ProjectTitle = styled.h1`
  font-size: 24px;
  font-family: "Prompt", sans-serif;
  margin-bottom: 10px;
  line-height: 1;

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    margin-top: 0;
  }
`;

const Info = styled.div`
  margin-bottom: 40px;
  line-height: 1.8;
`;

const DynamicContent = styled.div`
  line-height: 1.5;
  margin-bottom: 40px;
`;

const Spacer = styled.div`
  margin-bottom: 60px;
`;

const Nav = styled(Link)`
  color: #fff;
  display: inline-flex;
  align-items: center;

  &:hover {
    color: #ddd;
  }

  svg {
    font-size: 24px;
  }
`;

const PreviousSpan = styled.span`
  margin-left: 8px;
`;

const NextSpan = styled.span`
  margin-right: 8px;
`;

const NavMobile = styled.div`
  margin-bottom: 30px;

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    display: none;
  }
`;

const NavDesktop = styled.div`
  margin-bottom: 30px;
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    display: block;
  }
`;

const Full = styled.div`
  width: 100%;
`;

const FillMain = styled.div`
  flex: 1;
`;

export default Works;
